import "jquery";
import {} from "jquery-ujs";

$(function() {
  $('form[data-remote]').find('input,textarea,select').not('#extra_cost').on('change', function() {
    return $(this).closest('form').submit();
  });

  $('#extra_cost').on('keyup', function() {
    const form = $(this).closest('form');
    return delay((() => form.submit()), 500);
  });

  $('form_cancelled').on('change', function() {
    if ($(this).is(':checked')) {
      return $('#cancelledInfo').slideDown();
    } else {
      return $('#cancelledInfo').slideUp();
    }
  });

  $('input[name="form[sponsorshiptype]"]').on('change', check_sponsorship);
  $('input[name="form[regtype]"]').on('change', check_spouseguest);

  $('#form_exhibitorbadges').on('change', check_badge);
  $('#form_electricalhookup').on('change', check_elec);

  $('input[name="form[exhibitortype]"]').on('change', check_exhibitor);

  $('#ccPaymentSubmit').on('click', process_payment);

  const immediately = {data: { immediate: true }};
  check_spouseguest();
  check_sponsorship(immediately);
  check_badge(immediately);
  check_elec(immediately);
  return check_exhibitor(immediately);
});

var check_spouseguest = function() {
  const $input = $('input[name="form[regtype]"]').filter(':checked');
  if (($input.length > 0) && $input.val().match(/Spouse\/Guest/)) {
    return $('#spouse-guest span.important').show();
  } else {
    return $('#spouse-guest span.important').hide();
  }
};

const check_contributor = function() {
  const $input = $('input[name="form[sponsorshiptype]"]').filter(':checked');
  if (($input.length > 0) && $input.val().match(/Contributor|Golf/)) {
    return $('#form_sponsorshipcontribution').enable();
  } else {
    return $('#form_sponsorshipcontribution').disable();
  }
};

var check_sponsorship = function(e) {
  const immediate = checkImmediate();
  const authorized = $('#authorized');
  check_contributor();
  if (!$('#form_sponsorshiptype_not_applicable').is(':checked')) {
    return authorized.showSection(immediate);
  } else {
    return authorized.hideSection();
  }
};

var check_badge = function(e) {
  const immediate = checkImmediate();
  const badges = $('#badges');

  if ((e != null) && (e.data != null) && (e.data.forceClose === true)) {
    badges.hideSection();
    return true;
  }

  if ($('#form_exhibitorbadges').is(':checked')) {
    return badges.showSection(immediate);
  } else {
    return badges.hideSection();
  }
};

var check_elec = function(e) {
  const immediate = checkImmediate();
  const box = $('#elecav');
  if ($('#form_electricalhookup').is(':checked')) {
    return box.showSection(immediate);
  } else {
    return box.hideSection();
  }
};

var check_exhibitor = function(e) {
  const immediate = checkImmediate();
  const additional_options = $('#additional_options');
  const additional_specs = $('#additional_specs');

  if (!$('#form_exhibitortype_not_applicable').is(':checked')) {
    additional_options.showSection(immediate);
    additional_specs.showSection(immediate);
    return check_badge();
  } else {
    additional_options.hideSection();
    additional_specs.hideSection();
    return check_badge({data: { forceClose: true }});
  }
};

var process_payment = function(e) {
  e.preventDefault();
  $('#credit_card_form').submit();
  $('#ccPaymentSubmit').hide();
  $('#ccPaymentWait').show();
  return false;
};

var delay = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    return timer = setTimeout(callback, ms);
  };
})();

$.fn.enable = function() {
  return this.removeAttr('disabled');
};

$.fn.disable = function() {
  return this.attr("disabled", "disabled");
};

$.fn.toggleSection = function(direction, immediate) {
  if (immediate == null) { immediate = false; }
  return toggleSection(this, direction, immediate);
};

$.fn.hideSection = function(immediate) {
  if (immediate == null) { immediate = false; }
  return this.toggleSection('hide', immediate);
};

$.fn.showSection = function(immediate) {
  if (immediate == null) { immediate = false; }
  return this.toggleSection('show', immediate);
};

var checkImmediate = function(e) {
  if ((e != null) && (e.data != null)) { return e.data.immediate; } else { return false; }
};

var toggleSection = function(section, direction, immediate) {
  if (immediate == null) { immediate = false; }
  section = $(section);
  if (direction === 'show') {
    if (immediate) { section.show(); } else { section.slideDown(); }
    return section.find(':input').enable();
  } else if (direction === 'hide') {
    section.slideUp();
    return section.find(':input').disable();
  }
};